import React from "react"
import { navigate } from "gatsby"

const windowGlobal = typeof window !== 'undefined' && window

let lastUrl

if (windowGlobal) {
    lastUrl = windowGlobal.localStorage.getItem('lastUrl')
}

const IndexPage = () => {
    if ( lastUrl ) {
        navigate(lastUrl)
    }
    else if (windowGlobal) {
        navigate('/p/1')
    }
    return (
        <p></p>
    )
}
export default IndexPage